.btn {
    display: inline-block;
    padding: 8px 24px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &.btn-primary {
       background-color: #3977CE;
       color: #fff;
    }
    &.btn-secondary {
        color: #3977CE;
        background-color: transparent;
    }
    &.btn-primary:disabled {
      color: #FFF;
      background-color: #E2E2E2;
  }
    &.btn-secondary:disabled {
        color: #C8CFD8;
    }
    &.btn-deactivate {
      background: transparent;
    }
  }

  button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }